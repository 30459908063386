<template>
    <div v-loading="loading">
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='content'>
            <div class='top'>
                <h2 class='h2'>{{this.content.name}}</h2>
                <div class='swiper'>
                    <el-carousel :interval="3000" indicator-position="none" arrow="never">
                        <el-carousel-item v-for="(item,index) in picture" :key="index">
                            <!-- <img :src='item' alt /> -->
                             <el-image
                                style="width: 100%; height: 100%"
                                :src="item"
                                fit="cover"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>

            <div class='content'>
                <div>
                    <div class='label'>机具信息</div>
                    <el-row :gutter="20"  class='list content'>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>机具大类：</span>
                                <span>{{content.typeBig}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>机具小类：</span>
                                <span>{{content.typeSmall}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>机具品目：</span>
                                <span>{{content.typeItem}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>分档名称：</span>
                                <span>{{content.subFile}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>产品名称：</span>
                                <span>{{content.name}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>规格型号：</span>
                                <span>{{content.model}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>功率(kW)：</span>
                                <span>{{content.power}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>销售指导价格：</span>
                                <span>{{content.salePrice}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>中央补贴额：</span>
                                <span>{{content.countryMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>特殊县中央补贴额：</span>
                                <span>{{content.scountyCountryMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>省补贴额：</span>
                                <span>{{content.provinceMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>特殊县省补贴额：</span>
                                <span>{{content.scountyProvinceMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>市补贴额：</span>
                                <span>{{content.cityMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>特殊县市补贴额：</span>
                                <span>{{content.scountyCityMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                             <p>
                                <span>县补贴额：</span>
                                <span>{{content.countyMoney}}</span>
                            </p>
                        </el-col>
                        <el-col :span="12" class='flex'>
                            <p>
                                <span>特殊县县补贴额：</span>
                                <span>{{content.scountyCountyMoney}}</span>
                            </p>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <div class='label'>推广鉴定信息</div>
                    <div class='spring list'>
                        <p><span>编号证书：</span><span>{{this.content.certificateNumber}}</span></p>
                        <p><span>证书有效期截止日：</span><span>{{this.content.certificateEffectiveDate}}</span></p>
                        <el-popover
                            placement="bottom-start"
                            width="300"
                            trigger="click"
                            >
                            <p>{{content.modificationRecord}}</p>
                            <p class='link' slot="reference" :style="{textDecoration: 'underline', display : 'block',color: '#4083f9'}">点击查看企业修改证书编号、有效截止日期记录</p>
                        </el-popover>
                        <div class='link'>
                            <a
                            :href="content.testimonialUrl"
                            target="_blink"
                            :style="{color:(content.testimonialUrl === ''?'#999':'#4489fc')}"
                            >点击查看鉴定证书</a>
                        </div>
                        <div class='link'>
                            <a
                            :href="content.reportUrl"
                             target="_blink"
                            :class="{'gray': content.reportUrl === ''}"
                            :style="{color:(content.reportUrl === ''?'#999':'#4489fc')}"
                            >点击查看检验报告</a>
                        </div>
                        <div class='link'>
                            <p
                            :class="{'gray': content.nameplateUrl === ''}"
                            @click='showImg(content.nameplateUrl)'
                            :style="{textDecoration: 'underline', display : 'block',color:(content.nameplateUrl === ''?'#999':'#4489fc')}"
                            >点击查看铭牌图片</p>
                        </div>
                        <div class='link'>
                            <p
                            @click='showImg(content.productQualifiedUrl)'
                            :class="{'gray': content.productQualifiedUrl === ''}"
                            :style="{textDecoration: 'underline', display : 'block',color:(content.productQualifiedUrl === ''?'#999':'#4489fc')}"
                            >点击查看产品合格证图片</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class='label'>配置参数</div>
                    <div class='list'>
                        <p class='con'>{{content.configurationParameterEnterprise}}</p>
                    </div>
                </div>
                <div class='last'>
                    <div class='label'>生产企业信息</div>
                    <div class='spring list'>
                        <p><span>企业名称：</span><span>{{this.content.enterpriseName}}</span></p>
                        <p><span>法人代表：</span><span>{{this.content.enterpriseLegalPerson}}</span></p>
                        <p><span>电子邮箱：</span><span>{{this.content.enterpriseMailbox}}</span></p>
                        <p><span>销售联系人：</span><span>{{this.content.enterpriseSalesPerson}}</span></p>
                        <p><span>销售联系电话：</span><span>{{this.content.enterpriseSalesPhone}}</span></p>
                        <p><span>住所（经营场所）：</span><span>{{this.content.enterpriseAddress}}</span></p>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="isShowImageDialog"
            @closed="clearImg"
        >
            <el-carousel indicator-position="never" height="300px">
            <el-carousel-item v-for='(item, index) in img' :key='index'>
                <img :src="item" style="max-width: 100%;max-height: 100%;display: block; margin: 0 auto;"/>
            </el-carousel-item>
            </el-carousel>
        </el-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import { Image } from 'element-ui';
Vue.use(Image);
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            breadList: [{
                name: '数据查询'
            }, {
                name: '农机补贴数据 ',
                path: '/machineSubsidy'
            }, {
                name: '详情'
            }],
            content: {},
            picture: [],
            visible: false,
            isShowImageDialog: false,
            img: [],
            loading: true
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        async getDetail() {
            this.loading = true;
            const api = this.$fetchApi.subsidyEnquiryDetails.api + this.$route.query.id;
            const data = await this.$fetchData.fetchGet({}, api, 'json');
            this.loading = false;
            if (data.code === '200') {
                this.content = data.result;
                this.picture = data.result.picPathList;
            }
        },
        clearImg() {

        },
        showImg(url) {
            if (!url) {
                return false;
            }
            if (url.indexOf(',') < 0) {
                this.img = [url];
            } else {
                this.img = url.split(",");
            }
            console.log(this.img);
            this.isShowImageDialog = true;
        }
    }
    
};
</script>
<style lang="less" scoped>
    .content{
        width:1180px;
        margin: 0 auto;
        .list{
            padding:0 20px;
            font-size:16px;
            line-height: 30px;
        }
        /deep/ .el-col{
            color:#333; 
        }
        /deep/ .el-popover__reference{
            cursor: pointer;
        }
    }
    h2{
        text-align: center;
    }
    .swiper{
        width:600px;
        margin:0 auto;
        height:300px;
    }
    .label{
        font-weight: bold;
        height:22px;
        line-height: 22px;
        margin-bottom:20px;
        margin-top:40px;
    }
    .label::before{
        width: 6px;
        height: 20px;
        background-color: #265ae0;
        content: "";
        display: inline-block;
        margin-right:14px;
        position: relative;
        top:2px;
    }
    .last{
        padding-bottom:100px;
    }
    .link {
        a, p{
            cursor: pointer;
        }
        .gray {
            color: #999;
            pointer-events: none;
          }
    }
</style>